import { Auth } from '@aws-amplify/auth';
import { Hub, HubCallback } from '@aws-amplify/core';
import { ActivityCode, Audit, EntityType } from '@eppendorf/vnls-audit-event-client';
import { HasChildren } from '@eppendorf/vnls-react-components';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useCommitAuditEvent } from "$shared/audit-log/commit.api";
import { AuthContext, AuthContextValue } from '$shared/auth/auth-context';
import { redirectToLogin } from '$shared/fetch';

import { Loader } from '$components/loader/loader';

export function AuthContextProvider({ children }: HasChildren) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
  const { mutate } = useCommitAuditEvent();

  const signOut = useCallback(async () => {
    setIsAuthenticated(undefined);
    const currentUser = (await Auth.currentSession()).getIdToken().payload;
    const auditEvent = Audit.triggered(ActivityCode.LOGOUT)
      .byUser(currentUser?.email)
      .withTenantId(currentUser?.tenantId)
      .withAffected({
        type: EntityType.User,
        id: currentUser?.email,
      })
      .at(Date.now())
      .toJSON();

    mutate(auditEvent);
    await Auth.signOut();
  }, []);


  useEffect(() => {
    const listener: HubCallback = async ({ payload }) => {
      const currentUser = (await Auth?.currentSession())?.getIdToken()?.payload;
      if (payload.event === 'signIn') {
        const auditEvent = Audit.triggered(ActivityCode.LOGIN)
        .byUser(currentUser?.email)
        .withTenantId(currentUser?.tenantId)
        .withAffected({
          type: EntityType.User,
          id: currentUser?.email,
        })
        .at(Date.now())
        .toJSON();

        mutate(auditEvent);
      }
    };

    return Hub.listen('auth', listener);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    async function checkUserSession() {
      try {
        const session: CognitoUserSession = await Auth.currentSession();
        setIsAuthenticated(!!session);
      } catch (_) {
        setIsAuthenticated(false);
      }
    }

    checkUserSession().catch((error) => {
      // eslint-disable-next-line no-console -- maybe useful for debugging
      console.error(error);
    });
  }, []);

  const value: AuthContextValue = useMemo(
    () => ({
      isAuthenticated: !!isAuthenticated,
      isLoading: isAuthenticated === undefined,
      signIn: redirectToLogin,
      signOut,
    }),
    [isAuthenticated],
  );

  if (value.isLoading) {
    return <Loader />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
