import { DeviceParameterConfig } from '@eppendorf/vnls-application-configuration-service-types';
import { Device, RealtimeData } from '@eppendorf/vnls-telemetry-and-events-types';

export function findRealtimeForParam(
  realtimeList: Device['realtime'],
  param: DeviceParameterConfig,
): RealtimeData | undefined {
  const foundRealtime = realtimeList.filter(
    (realtime) =>
      realtime &&
      (realtime.subject === param.subject || realtime.name === param.name) &&
      realtime.dataType === 'telemetry' &&
      realtime.subcomponent.indexOf('#') <= 0,
  );

  // Sense Monitor quick-fix (Should be obsolete once we have Sensors as first-tier assets, see Stefans comment here as a hint: https://jira.dasgip.de/browse/ESEVNLS-3296)
  // Explanation:
  // When two different sensors have been connected to one socket (e.g. left), both sensors are represented in the realtime data of the device.
  // (They have the same name (e.g. Temperature (Probe) Right), but different subjects (e.g. 31629460-564a-5cd9-b92a-159e36a3b033))
  // Here, we need to find the most up-to-date value, aka finding the currently connected sensor.
  if (foundRealtime.length > 1) {
    return (
      foundRealtime.reduce((acc, curr) => {
        if (curr?.timestamp && acc?.timestamp && curr?.timestamp > acc?.timestamp) {
          return curr;
        }

        return acc;
      }, foundRealtime[0]) || undefined
    );
  }

  return foundRealtime[0] || undefined;
}
