import { Input } from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import './verify-code-component.scss';

export interface VerificationCodeInputProps {
  handleCodeChange: (newCode: string) => void;
  codeLength: number;
  hasError: boolean;
  errorMessage: string;
  cleanInputValues: boolean;
}

export function VerificationCodeInput({
  handleCodeChange,
  codeLength,
  hasError,
  errorMessage,
  cleanInputValues,
  ...props
}: VerificationCodeInputProps) {
  const [code, setCode] = useState(Array(codeLength).fill(''));
  const inputRefs = useRef<(React.RefObject<HTMLInputElement | null>)[]>(
    Array.from({ length: codeLength }, () => React.createRef<HTMLInputElement>()),
  );

  const validate = (input: string) => /^\d/.test(input);

  const focusControl = (indexToFocus: number) => {
    if (indexToFocus >= 0 && indexToFocus < codeLength) {
      // eslint-disable-next-line security/detect-object-injection -- it is ok to access the array by index
      inputRefs.current[indexToFocus].current?.focus();
    }
  };

  useEffect(() => {
    focusControl(0);
    setCode(Array(codeLength).fill(''));
  }, [cleanInputValues]);

  useEffect(() => {
    focusControl(0);
  }, []);

  const updateState = (value: number | string, index: number) => {
    const newCode = [...code];
    // eslint-disable-next-line security/detect-object-injection -- it is ok to access the array by index
    newCode[index] = value;
    setCode(newCode);
    handleCodeChange(newCode.join(''));
  };

  // Using the onInputKeyDown event instead of onChange because the onChange event is not triggered when the input is full.
  // Besides that if you move the focus to the previous input field, and update the value of the current input field
  // this is not updated
  const onInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    const eventKey = event.key;
    const newCode = [...code];

    if (eventKey === 'Backspace' || eventKey === 'Delete') {
      // eslint-disable-next-line security/detect-object-injection -- it is ok to access the array by index
      if (newCode[index] === '' && index > 0) {
        const newIndex = index - 1;
        // If the textbox was cleared already, move to the previous one
        // move the focus to the previous input field
        updateState('', newIndex);
        focusControl(newIndex);
      } else {
        // Just clean the current textbox
        updateState('', index);
      }
    } else {
      if (!validate(eventKey)) return;
      updateState(eventKey, index);
      focusControl(index + 1);
    }
  };

  return (
    <div className="flex flex__dir--column gap-s">
      <div className="flex flex__dir--row flex__jc--center gap-s" key="verify">
        {code.map((value, index) => (
          <div
            className="col-2 verifyCodeInputComponent"
            key={`verificationCode-${index * 2}`}
            data-testid={`verificationCode-${index.toString()}`}
          >
            <Input
              maxLength={1}
              className={cn('input-full-size', {
                'border-color-red-500': hasError,
              })}
              id={`verificationCode-${index.toString()}`}
              key={`verificationCode-${index * 2}`}
              type="text"
              // eslint-disable-next-line security/detect-object-injection -- it is ok to access the array by index
              ref={inputRefs.current[index] as RefObject<HTMLInputElement>}
              // eslint-disable-next-line security/detect-object-injection -- it is ok to access the array by index
              value={code[index]}
              onKeyDownCapture={(e) => onInputKeyDown(e, index)}
              // This onChange is empty because the value is controlled by onKeyDownCapture
              // Because the onChange event is not triggered when the input is full, causing a conflict with the onInputKeyDown event
              onChange={() => {}}
              // eslint-disable-next-line react/jsx-props-no-spreading -- It is safe to spread props
              {...props}
            />
          </div>
        ))}
      </div>
      {hasError && (
        <span className="color-red-500 text-align-center">{errorMessage}</span>
      )}
    </div>
  );
}
