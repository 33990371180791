import type { IncidentDTO } from '@eppendorf/vnls-notifications-utils';
import { QueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';

import { prepopulateCache } from '$shared/utils/react-query.utils';

import { IncidentDetailSidecar } from '$features/incidents/incident-detail-sidecar';
import { createIncidentQuery } from '$features/incidents/incidents.api';

export async function incidentDetailLoader(
  queryClient: QueryClient,
  incidentId: string,
): Promise<null> {
  return prepopulateCache<IncidentDTO>(queryClient, createIncidentQuery(incidentId));
}

export function IncidentDetailPage(): ReactElement {
  return <IncidentDetailSidecar />;
}
