import type { HasChildren } from '@eppendorf/vnls-react-components';

export function SidebarBadge({ children }: HasChildren) {
  return children ? (
    <div
      className="color-white bg-red-500 border-radius-xxl border-s border-color-white font-size-xs flex flex__ai--center flex__jc--center bs-default"
      style={{ height: '20px', width: '20px' }}
    >
      {children}
    </div>
  ) : null;
}
