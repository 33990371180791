// eslint-disable-next-line eslint-comments/disable-enable-pair -- !
/* eslint-disable security/detect-object-injection -- ! */
import { Button, Dialog } from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAccessControlGuard } from '$shared/custom-hooks/useAccessControlGuard';

import { CancelEditDialog } from '$components/cancel-edit-dialog/cancel-edit-dialog';
import { ErrorDialogRetry } from '$components/error-dialog-retry/error-dialog-retry';
import { useSidecarContext } from '$components/sidecar/sidecar-context-hook';
import { EditInfoItemTextarea } from '$features/devices/device-information/edit-info-item-textarea';
import { useGetDevice, useUpdateDeviceNotes } from '$features/devices/devices.api';
import { resolveDeviceMetaDataValue } from '$features/devices/devices.utils';

export function DeviceNotes() {
  const [searchParams] = useSearchParams();
  const refForScroll = useRef<HTMLDivElement>(null);
  const submitRef = useRef<HTMLFormElement>(null);

  const [isValid, setIsValid] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isRetryDialogOpen, setIsRetryDialogOpen] = useState(false);
  const methods = useFormContext();
  const navigate = useNavigate();

  const {
    mutateAsync,
    error: updateDeviceError,
    isPending: isUpdateDeviceLoading,
    isSuccess: isUpdateDeviceSuccess,
  } = useUpdateDeviceNotes();

  let { data } = useGetDevice({
    manufacturer: searchParams.get('manufacturer') ?? '',
    serialNumber: searchParams.get('serialNumber') ?? '',
  });

  const { isSidecardClosing, isCancelDialogOpen, setIsCancelDialogOpen } =
    useSidecarContext();

  const hasUpdateDeviceNotesPermissions = useAccessControlGuard('UPDATE_DEVICE_NOTES');

  const onError = () => {
    setIsValid(false);
  };

  useEffect(() => {
    if (!updateDeviceError) {
      setIsRetryDialogOpen(true);
    }
  }, [updateDeviceError]);

  const resetToEditView = (keepFormData = false) => {
    setIsCancelDialogOpen(false);
    setEditMode(true);
    setIsSubmitted(false);
    if (!keepFormData) methods.reset({ ...data });
  };

  const resetToReadView = () => {
    setIsCancelDialogOpen(false);
    setEditMode(false);
    methods.reset(data);
  };
  useEffect(() => {
    if (updateDeviceError) {
      resetToEditView(true);
    }

    if (isUpdateDeviceSuccess) {
      resetToReadView();
    }
  }, [isUpdateDeviceSuccess, updateDeviceError]);

  const onSubmit = async (modifiedData: Record<string, string | number | object>) => {
    if (data) {
      try {
        setIsSubmitted(true);
        data = await mutateAsync({
          manufacturer: data.manufacturer,
          serialNumber: data.serialNumber,
          notes: modifiedData.notes as string,
        });
        setEditMode(false);
      } catch {
        onError();
      }
    } else {
      setEditMode(false);
    }
  };

  function handleDialogOnOpenChange(): void {
    setIsCancelDialogOpen(false);
  }

  function handleDialogDiscard(): void {
    resetToReadView();

    if (isSidecardClosing) {
      navigate({
        search: '',
      });
    }
  }

  function handleDialogSaveChanges(): void {
    methods.handleSubmit(onSubmit)();
    if (isSidecardClosing) {
      navigate({
        search: '',
      });
    }
  }

  return (
    <>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="overflow-hidden h-full overflow-y-auto">
          <div className="sub-title m-y-xxl">
            {t('deviceDetail.deviceInformation.notes')}
          </div>
          {!editMode && (
            <div className="flex m-bottom-l flex__ai--center">
              <div
                className={cn('color-gray-900', 'body-regular')}
                style={{ whiteSpace: 'pre-line' }}
              >
                {resolveDeviceMetaDataValue(data?.notes) || ''}
              </div>
            </div>
          )}
          {editMode && <EditInfoItemTextarea id="notes" maxLength={2500} />}
          <div className="m-top-xxl flex flex__jc--flex-end">
            {hasUpdateDeviceNotesPermissions &&
              !editMode &&
              data?.connectionStatus === 'paired' && (
                <Button
                  size="small"
                  onClick={() => {
                    resetToEditView();
                    refForScroll.current?.scrollIntoView();
                  }}
                >
                  {t('shared.edit')}
                </Button>
              )}
            {editMode && (
              <div className="dialog-content__actions-wrapper m-top-xxl">
                <Button
                  onClick={() => {
                    resetToReadView();
                  }}
                  variant="secondary"
                  size="small"
                  disabled={isUpdateDeviceLoading}
                >
                  {t('shared.cancel')}
                </Button>
                <Button
                  type="submit"
                  size="small"
                  disabled={(isSubmitted && !isValid) || isUpdateDeviceLoading}
                >
                  {t('shared.save')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </form>

      {updateDeviceError && (
        <Dialog
          isOpen={isRetryDialogOpen}
          onOpenChange={(state) => setIsRetryDialogOpen(state)}
          hideCloseButton
        >
          <ErrorDialogRetry
            title={t('errors.errorOccurred')}
            message={t('errors.unexpected')}
            onTryAgain={() => {
              submitRef?.current?.dispatchEvent(
                new Event('submit', { cancelable: true, bubbles: true }),
              );
            }}
          />
        </Dialog>
      )}

      <CancelEditDialog
        isOpen={isCancelDialogOpen}
        onOpenChange={() => handleDialogOnOpenChange()}
        onDiscardChanges={() => handleDialogDiscard()}
        onSaveChanges={() => handleDialogSaveChanges()}
        isLoading={isUpdateDeviceLoading}
      />
    </>
  );
}
