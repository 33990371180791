import { Device } from '@eppendorf/vnls-inventory-service-types';
import {
  Button,
  HasClassName,
  Icon,
  IconSizeClasses,
} from '@eppendorf/vnls-react-components';
import { useQuery } from '@tanstack/react-query';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccessControlGuard } from '$shared/custom-hooks/useAccessControlGuard';
import { queryClient } from '$shared/query/query-client';

import {
  createDeviceQuery,
  devicesQueryKey,
  useReregisterDevice,
} from '$features/devices/devices.api';

interface DeviceDetailRetryRegProps extends HasClassName {
  device: Device;
}

export function DeviceDetailRetryReg({
  className,
  device,
}: DeviceDetailRetryRegProps): ReactElement | null {
  const { t } = useTranslation();
  const [retryState, setRetryState] = useState<'initital' | 'retrying' | 'retryfailed'>(
    'initital',
  );

  const { mutateAsync: reregisterDevice } = useReregisterDevice();
  const { data: deviceRes } = useQuery({
    ...createDeviceQuery({
      manufacturer: 'Eppendorf',
      serialNumber: device.serialNumber ?? '',
    }),
    enabled: retryState === 'retrying',
    refetchInterval: 1000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
  });
  const hasPermissionToRegisterDevice = useAccessControlGuard('ADD_DEVICE');

  async function tryAgain(): Promise<void> {
    await reregisterDevice({
      manufacturer: 'Eppendorf',
      serialNumber: device.serialNumber,
    });
    setRetryState('retrying');
  }

  useEffect(() => {
    if (deviceRes?.connectionStatus === 'paired') {
      queryClient.invalidateQueries({ queryKey: [devicesQueryKey] });
    }
  }, [deviceRes?.connectionStatus]);

  useEffect(() => {
    // eslint-disable-next-line no-undef -- working
    let waitForDeviceToExistTimer: NodeJS.Timeout;
    if (retryState === 'retrying') {
      waitForDeviceToExistTimer = setTimeout(() => {
        setRetryState('retryfailed');
      }, 15000);
    }

    return () => {
      clearTimeout(waitForDeviceToExistTimer);
    };
  }, [device, retryState]);

  if (device?.connectionStatus === 'paired') {
    return null;
  }

  return (
    <div
      className={`${className} flex flex__ai--center bg-blue-500 color-white border-radius-xs`}
    >
      <div className="flex-grow m-right-m m-left-m">
        <div className="flex flex__ai--center p-y-m">
          <Icon
            name="info"
            size={IconSizeClasses.XSmall}
            className="bg-white m-right-s"
          />
          {retryState === 'initital' && t('addDevice.retryRegistration.hint')}
          {retryState === 'retrying' && t('addDevice.retryRegistration.pleaseWait')}
          {retryState === 'retryfailed' && (
            <div className="flex flex__dir--column">
              <div className="m-bottom-xxs">{t('addDevice.retryRegistration.hint')}</div>
              <p>
                <span>{t('errors.contactSupport')} </span>

                {/* TOOD: We dont have a Link/Anchor/.. component yet, see: https://github.com/eppendorf/vn-browser-libs/issues/202 */}
                <a
                  href="https://www.eppendorf.com/service-support/"
                  className="btn-base btn btn--tertiary btn--info btn--small font-weight-regular color-white"
                  target="_blank"
                  style={{ display: 'inline', textDecoration: 'underline' }}
                  rel="noreferrer"
                >
                  {t('errors.contactSupport2')}
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
      {retryState !== 'retrying' && hasPermissionToRegisterDevice && (
        <div className="border-solid border-left-s">
          <Button onClick={() => tryAgain()}>
            <Icon
              name="reset"
              size={IconSizeClasses.XSmall}
              className="bg-white m-right-s"
            />
            {t('shared.tryAgain')}
          </Button>
        </div>
      )}
    </div>
  );
}
