import { AuditEvent } from '@eppendorf/vnls-audit-event-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { fetcher } from '$shared/fetch';
import { AUDIT_LOGS_BASE_PATH } from '$shared/utils/api-paths';

export const auditLogQueryKey = 'audit-logs';

export const useCommitAuditEvent = () => {
  const commitAuditEvent = (event: AuditEvent) =>
    fetcher.post<AuditEvent, void>(AUDIT_LOGS_BASE_PATH, event);

  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<void>, AxiosError, AuditEvent>({
    mutationFn: commitAuditEvent,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [auditLogQueryKey] });
    },
  });
};
