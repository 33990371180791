import { Badge, Button, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import {
  User,
  UserStatus,
  PhoneNumberVerificationStatus,
} from '@eppendorf/vnls-user-tenant-utils';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTokenAttributes } from '$shared/custom-hooks/useTokenAttributes';
import { isFeatureEnabled } from '$shared/feature-toggle/is-feature-enabled';
import { getLocalizedDate } from '$shared/utils/date.utils';

import { stringifyUserRoles } from '$features/users/roles.utils';
import { useResendInvite } from '$features/users/users.api';

export interface EditViewProps {
  onEdit: () => void;
  user: User;
  onVerifyPhoneNumberClick: () => void;
}

type UserDisplayField = {
  label: string;
  name: keyof User | string;
  customValue?: (user: User) => string | ReactElement | null;
};

export function ReadView({
  onEdit,
  user,
  onVerifyPhoneNumberClick,
}: EditViewProps): ReactElement {
  const { t } = useTranslation();
  const [inviteResent, setInviteResent] = useState(false);

  const { mutateAsync, isError, isPending } = useResendInvite();

  const isPhoneNumberFeatureEnabled = isFeatureEnabled('addUpdatePhoneNumber');
  const isVerifyPhoneNumberFeatureEnabled = isFeatureEnabled('verifyPhoneNumber');

  const { email } = useTokenAttributes(['email']);

  async function handleInviteResend(): Promise<void> {
    try {
      if (user) await mutateAsync(user.id);
      setInviteResent(true);
    } catch {
      // eslint-disable-next-line no-useless-return -- disables test error output
      return;
    }
  }

  let displayedUserFields: UserDisplayField[] = [
    {
      label: t('userManagement.firstName'),
      name: 'firstName',
    },
    {
      label: t('userManagement.lastName'),
      name: 'lastName',
    },
    {
      label: t('shared.email'),
      name: 'email',
    },
    {
      label: t('userManagement.role'),
      name: 'role',
      customValue: () => `${stringifyUserRoles(user.roles || [])}`,
    },
    {
      label: t('userManagement.invitedAt'),
      name: 'formattedInviteSentAt',
      // eslint-disable-next-line react/no-unstable-nested-components -- its fine
      customValue: () =>
        inviteResent ? (
          <div className="flex flex__ai--center">
            <Icon
              name="success"
              size={IconSizeClasses.XSmall}
              className="bg-green-500 flex m-right-s"
            />

            <p className="color-green-500 flex font-weight-bold">
              {t('userInvite.inviteResentSuccessfully')}
            </p>
          </div>
        ) : (
          `by ${user.createdBy} on ${
            user.inviteSentAt ? getLocalizedDate(user.inviteSentAt) : ''
          } `
        ),
    },
    {
      label: '',
      name: 'inviteButton',
      // eslint-disable-next-line react/no-unstable-nested-components -- we need the button here
      customValue: () =>
        user?.status === UserStatus['Invite Sent'] && !inviteResent ? (
          <Button
            variant="tertiary"
            size="small"
            onClick={() => handleInviteResend()}
            className="m-left-9xl "
            disabled={isPending || isError}
          >
            <span className="invite-margin">{t('userManagement.resendInvite')}</span>
          </Button>
        ) : null,
    },
  ];

  if (isPhoneNumberFeatureEnabled) {
    displayedUserFields = [
      ...displayedUserFields.slice(0, 3),
      {
        label: t('userManagement.phoneNumber'),
        name: 'phoneNumber',
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted
        customValue: () => {
          if (user.phoneNumbers && user.phoneNumbers.length > 0) {
            const phoneNumber = user.phoneNumbers?.[0];
            const phoneNumberVerificationNotSuccessful =
              phoneNumber?.status !== PhoneNumberVerificationStatus.SUCCESSFUL;

            return (
              <>
                <div className="flex flex__ai--center">
                  <p>{`${phoneNumber?.countryCode} ${phoneNumber?.number}`}</p>
                  {phoneNumberVerificationNotSuccessful ? (
                    <Badge className="m-left-m" size="small" color="orange">
                      {t('userManagement.phoneNumberNotVerified')}
                    </Badge>
                  ) : (
                    <Badge className="m-left-m" size="small" color="green">
                      {t('userManagement.phoneNumberVerified')}
                    </Badge>
                  )}
                </div>
                {phoneNumberVerificationNotSuccessful &&
                isVerifyPhoneNumberFeatureEnabled &&
                user.email === email ? (
                  <div className="flex flex__ai--center">
                    <Button
                      onClick={onVerifyPhoneNumberClick}
                      type="button"
                      variant="tertiary"
                    >
                      <p
                        className="color-blue-500"
                        style={{ fontSize: '16px', cursor: 'pointer' }}
                      >
                        {t('verifyPhoneNumber.title')}
                      </p>
                    </Button>
                  </div>
                ) : null}
              </>
            );
          }
          return '-';
        },
      },
      ...displayedUserFields.slice(3, displayedUserFields.length),
    ];
  }

  const lastUpdatedField: UserDisplayField = {
    label: t('userManagement.lastUpdated'),
    name: 'customField',
    customValue: () =>
      user.updatedBy
        ? `by ${user.updatedBy} on ${
            user.updatedAt ? getLocalizedDate(user.updatedAt) : ''
          }`
        : null,
  };

  const getClassByLabel = (label: string): string => {
    switch (label) {
      case t('userManagement.firstName'):
        return '';
      case '':
        return 'p-top-3xs';
      default:
        return 'p-top-s';
    }
  };

  return (
    <>
      {displayedUserFields.map(({ label, name, customValue }, i) => (
        <React.Fragment key={label}>
          <div className={`row ${getClassByLabel(label)}`}>
            <div className="col-3">
              <h3 className="body-bold">{label}</h3>
            </div>
            {/* eslint-disable-next-line security/detect-object-injection -- we know the values */}
            <div className="col-9 ">
              {user && (
                <div>
                  {customValue
                    ? (customValue(user) as React.ReactNode)
                    : (user[name as keyof Omit<User, 'phoneNumbers'>] as React.ReactNode)}
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      ))}

      {user.updatedBy && (
        <div className={`row ${getClassByLabel(lastUpdatedField.label)}`}>
          <div className="col-3">
            <h3 className="body-bold">{lastUpdatedField.label}</h3>
          </div>
          <div className="col-9">
            <div>
              {lastUpdatedField.customValue ? lastUpdatedField.customValue(user) : ''}
            </div>
          </div>
        </div>
      )}

      <div className="dialog-content__actions-wrapper m-top-xxl">
        <Button size="small" onClick={onEdit}>
          {t('shared.edit')}
        </Button>
      </div>
    </>
  );
}
