import type {
  DeviceModels,
  DeviceTypeConfig,
} from '@eppendorf/vnls-application-configuration-service-types';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { fetcher } from '$shared/fetch';
import { createAppConfigPath } from '$shared/utils/api-paths';

export const appConfigQueryKey = 'application-configuration';

export const createGetDeviceTypeConfigsQuery = () => ({
  queryKey: [appConfigQueryKey, 'device-type-configs'],
  queryFn: async () =>
    fetcher.get<DeviceTypeConfig<DeviceModels>[]>(createAppConfigPath('device-types')),
  placeholderData: keepPreviousData,
  staleTime: Infinity,
  cacheTime: Infinity,
});
export const useGetDeviceTypeConfigs = () => useQuery(createGetDeviceTypeConfigsQuery());

export const createGetFeatureToggleQuery = () => ({
  queryKey: [appConfigQueryKey, 'feature-toggles'],
  queryFn: async () =>
    fetcher.get<string[]>(createAppConfigPath('feature-toggles')),
  placeholderData: keepPreviousData,
  staleTime: Infinity,
  cacheTime: Infinity,
});

export const useGetFeatureToggle = () => useQuery(createGetFeatureToggleQuery());
