import { Icon } from '@eppendorf/vnls-react-components';
import { useSearchParams } from 'react-router-dom';
import { useDebounce, useUnmount } from 'react-use';

import {
  useGetIncident,
  useUpdateIncidentReadStatus,
} from '$features/incidents/incidents.api';

export function IncidentFlow() {
  const [searchParams] = useSearchParams();
  const incidentId = searchParams.get('id') || '';
  const { data } = useGetIncident(incidentId);
  const { mutate } = useUpdateIncidentReadStatus();

  const [, cancel] = useDebounce(
    () => {
      if (!data?.isRead) {
        mutate({ incidentId, options: { markAsRead: true } });
      }
    },
    1000,
    [data],
  );

  useUnmount(cancel);

  // Will be replaced with a proper UI later
  return (
    <>
      <p>Device serial number: {data?.device?.serialNumber}</p>
      <div className="m-y-m flex flex__ai--center">
        Incident status:
        {data?.isRead ? (
          <>
            <Icon name="checkmark" className="bg-green-500" /> read
          </>
        ) : (
          <>
            <Icon name="close" className="bg-red-500" /> unread
          </>
        )}
      </div>
    </>
  );
}
