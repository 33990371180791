import { PaginatedResponse } from '@eppendorf/vnls-notification-service-types';
import {
  Device,
  PaginatedDevices,
  RealtimeData,
  RealtimeDataBatch,
} from '@eppendorf/vnls-telemetry-and-events-types';
import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import gql from 'graphql-tag';
import { useEffect } from 'react';
import type { ZenObservable } from 'zen-observable-ts';

import {
  GraphQLAmplifyClient,
  amplifySubscription,
} from '$shared/graphql/graphql-client';
import { queryClient } from '$shared/query/query-client';

export const monitoringQueryKey = 'monitoring';

export const createRealtimeDevicesQueryWithQueryParams = (
  page = 1,
  pageSize = 10,
): UseQueryOptions<PaginatedDevices> => {
  const devicesDocument = gql(`
    query RealtimeDevices {
      devices(page: ${page}, pageSize: ${pageSize}) {
        data {
          serialNumber
          manufacturer
          model
          name
          type
          connectionStatus
          createdAt
          realtime {
            dataType
            name
            subcomponent
            subject
            timestamp
            unit
            value
            valueType
            fractionalDigits
          }
        }
        pagination {
          page
          pageSize
          totalPages
          totalItems
        }
      }
    }
  `);

  return {
    queryKey: [monitoringQueryKey],
    queryFn: async (): Promise<PaginatedDevices> => {
      const queryResponse = await GraphQLAmplifyClient.query<{
        data: { devices: PaginatedDevices };
      }>(devicesDocument.loc?.source.body || '', {});

      const devices = queryResponse.data?.devices;

      if (!devices) {
        return Promise.reject(new Error('No devices found'));
      }

      devices.data?.forEach((device: Device | null) => {
        if (device !== null) {
          queryClient.setQueryData(
            [monitoringQueryKey, device.manufacturer, device.serialNumber],
            device,
          );
        }
      });
      return devices;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  };
};

export const createRealtimeDeviceQuery = (
  manufacturer: string,
  serialNumber: string,
): UseQueryOptions<Device> => {
  const deviceQuery = gql(`
  query RealtimeDevice {
    device(serialNumber: "${serialNumber}", manufacturer: "${manufacturer}") {
      realtime {
        dataType
        name
        subcomponent
        subject
        timestamp
        unit
        value
        valueType
        fractionalDigits
      }
      serialNumber
      manufacturer
      model
      name
      type
      connectionStatus
    }
  }`);

  return {
    queryKey: [monitoringQueryKey, manufacturer, serialNumber],
    queryFn: async (): Promise<Device> => {
      try {
        const data = await GraphQLAmplifyClient.query<{ data: { device: Device } }>(
          deviceQuery.loc?.source.body || '',
          {},
        );

        return data.data.device;
      } catch (error) {
        return Promise.reject(new Error('No device found'));
      }
    },
    refetchOnWindowFocus: false,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- we don't know the actual type
export const useRealtimeDevices = (page = 1, pageSize = 10) =>
  useQuery(createRealtimeDevicesQueryWithQueryParams(page, pageSize));

export const useRealtimeDevice = (
  manufacturer: string,
  serialNumber: string,
): UseQueryResult<Device> & { invalidate: () => Promise<Device> } => {
  const deviceQuery = createRealtimeDeviceQuery(manufacturer, serialNumber);
  return {
    ...useQuery(deviceQuery),
    invalidate: async (): Promise<Device> => {
      await queryClient.invalidateQueries({ queryKey: [monitoringQueryKey] });

      return queryClient.fetchQuery(deviceQuery);
    },
  };
};

export function updateDeviceRealtimeData(previousDevice: Device, data: RealtimeData) {
  return {
    ...previousDevice,
    realtime:
      previousDevice?.realtime.map((item) => {
        if (
          item?.subject === data.subject &&
          item?.subcomponent === data.subcomponent &&
          (item?.timestamp ?? 0) < (data.timestamp ?? 0)
        ) {
          return data;
        }
        return item;
      }) || [],
  };
}

export const subscribeToRealtimeDevices = () => {
  const subQuery = gql(`
    subscription{
      devicesRealtimeData {
        tenantId
        data {
          dataType
          name
          manufacturer
          serialNumber
          subcomponent
          subject
          timestamp
          unit
          value
          valueType
          fractionalDigits
        }
      }
    }
  `);

  const qC = useQueryClient();

  function updateDeviceThatExistsInCollection(
    previousDevice: Device,
    previousDevicesData: PaginatedResponse<Device>,
    data: RealtimeData,
    queryKey: string[],
  ) {
    const { serialNumber, manufacturer } = previousDevice;

    const updatedData = previousDevicesData?.data?.map((device) => {
      if (device.serialNumber === data.serialNumber) {
        const updatedDevice = updateDeviceRealtimeData(device, data);
        qC.setQueryData<Device>([...queryKey, manufacturer, serialNumber], updatedDevice);
        return updatedDevice;
      }
      return device;
    });

    qC.setQueryData(queryKey, {
      ...previousDevicesData,
      data: updatedData,
    });
  }

  function updateDeviceThatDoesNotExistInCollection(
    previousDeviceData: Device,
    data: RealtimeData,
    queryKey: string[],
    manufacturer: string,
    serialNumber: string,
  ) {
    const updatedDevice = updateDeviceRealtimeData(previousDeviceData, data);
    qC.setQueryData<Device>([...queryKey, manufacturer, serialNumber], updatedDevice);
  }

  useEffect(() => {
    let subscription: ZenObservable.Subscription;
    async function subscribeToDevicesRealtimeData() {
      const sub = await amplifySubscription<{ devicesRealtimeData: RealtimeDataBatch }>(
        subQuery.loc?.source.body || '',
        {},
      );

      subscription = sub?.subscribe({
        next: ({ value }) => {
          if (!Array.isArray(value.data?.devicesRealtimeData?.data)) {
            return;
          }

          value.data?.devicesRealtimeData?.data.forEach((entry) => {
            if (!entry) {
              return;
            }
            const queryKey = [monitoringQueryKey];
            const previousDevices = qC.getQueryData<PaginatedResponse<Device>>(queryKey);

            const previousDeviceInCollection = previousDevices?.data?.find(
              (device) => device?.serialNumber === entry.serialNumber,
            );

            if (previousDeviceInCollection && previousDevices) {
              updateDeviceThatExistsInCollection(
                previousDeviceInCollection,
                previousDevices,
                entry,
                queryKey,
              );
            } else {
              const { serialNumber, manufacturer } = entry;

              const previousDevice = qC.getQueryData<Device>([
                ...queryKey,
                manufacturer,
                serialNumber,
              ]);

              if (previousDevice) {
                updateDeviceThatDoesNotExistInCollection(
                  previousDevice,
                  entry,
                  queryKey,
                  manufacturer,
                  serialNumber,
                );
              }
            }
          });
        },
        // eslint-disable-next-line no-console -- we don't know yet what to do with the error
        error: (error: unknown) => console.error(error),
      });
    }

    subscribeToDevicesRealtimeData();
    return () => {
      subscription?.unsubscribe();
    };
  }, [qC]);
};
