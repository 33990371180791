import { Sidecar } from '@eppendorf/vnls-react-components';
import { type ReactElement, useState } from 'react';
import { useNavigate, useRouteError, useSearchParams } from 'react-router-dom';

import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { SidecarRouterOutlet } from '$components/sidecar-router/sidecar-router-outlet';
import { useGetIncident } from '$features/incidents/incidents.api';

export function IncidentDetailSidecar(): ReactElement {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isSidecarOpen, setIsSidecarOpen] = useState(true);
  const routeError = useRouteError();
  const { isError } = useGetIncident(searchParams.get('id') || '');

  function handleSidecarOpenChange() {
    setIsSidecarOpen(false);
    navigate({ search: '' });
  }

  return (
    <Sidecar isOpen={isSidecarOpen} onOpenChange={() => handleSidecarOpenChange()}>
      {isError || routeError ? (
        <LoadingErrorHint className="m-top-xl" />
      ) : (
        <div className="flex flex__dir--column flex__ai--stretch h-full">
          <Sidecar.Title>Incident detail</Sidecar.Title>
          <div className="box">
            <SidecarRouterOutlet />
          </div>
        </div>
      )}
    </Sidecar>
  );
}
