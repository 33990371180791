import { useMutation } from '@tanstack/react-query';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';

import { fetcher } from '$shared/fetch';
import { MONITORING_BASE_PATH } from '$shared/utils/api-paths';

export interface MonitoringExportParameters {
  subject: string;
  name: string;
}

export interface MonitoringExportQueryParams {
  serialNumber: string;
  parameters: MonitoringExportParameters[] | [];
}

export const useMonitoringExportWithQueryParams = (params: MonitoringExportQueryParams) =>
  useMutation({
    mutationFn: () =>
      fetcher.post<MonitoringExportQueryParams, Blob>(
        `${MONITORING_BASE_PATH}/actions/export`,
        params,
        { responseType: 'blob', timeout: 61_000 },
      ),
    onSuccess: (response) => {
      const blobURL = URL.createObjectURL(
        new Blob([response.data], { type: 'application/zip' }),
      );
      const link = document.createElement('a') as HTMLAnchorElement;
      // TODO: expose content-disposition header and use a filename from the response
      const from = startOfDay(subDays(Date.now(), 7));
      const to = endOfDay(Date.now());
      const filename = `${params.serialNumber}-${format(from, 'yyyy-MM-dd')}--${format(to, 'yyyy-MM-dd')}`;

      link.href = blobURL;
      link.download = `${filename}.zip`;
      link.click();

      URL.revokeObjectURL(blobURL);
    },
  });
