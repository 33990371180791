import { QueryClient, QueryKey, UseQueryOptions } from '@tanstack/react-query';

import { FeatureToggle } from '$shared/feature-toggle/types';

import { useGetFeatureToggle } from '$components/app-config/app-config.api';

const checkIsFeatureEnabled = (feature: FeatureToggle, featureList: string[] = []) =>
  featureList?.includes(feature);

export const isFeatureEnabled = (feature: FeatureToggle) => {
  const { data: featureList } = useGetFeatureToggle();

  return checkIsFeatureEnabled(feature, featureList);
};

export const isFeatureEnabledInCache = async <T>(
  queryClient: QueryClient,
  featureToggle: FeatureToggle,
  query: UseQueryOptions<T>,
  queryKey: QueryKey,
) => {
  await queryClient.prefetchQuery(query);

  const cache = queryClient.getQueryCache().find<string[]>({ queryKey });

  return checkIsFeatureEnabled(featureToggle, cache?.state.data);
};
