import type {
  IncidentDTO,
  UpdateReadStatusIncidentBody,
} from '@eppendorf/vnls-notifications-utils';
import { PaginatedResponse } from '@eppendorf/vnls-user-tenant-utils';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import { fetcher } from '$shared/fetch';
import {
  createPathWithQueryParams,
  INCIDENTS_BASE_PATH,
  objectToURLSearchParams,
} from '$shared/utils/api-paths';

export const incidentsQueryKey = 'incidents';
export const unreadIncidentsCountQueryKey = 'unread-incidents-count';

export const createIncidentsQueryWithQueryParams = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- we don't know the actual type
  queryParams: any,
) => ({
  queryKey: [incidentsQueryKey, ...objectToURLSearchParams(queryParams).values()],
  queryFn: async () =>
    fetcher.get<PaginatedResponse<IncidentDTO>>(
      createPathWithQueryParams(INCIDENTS_BASE_PATH, queryParams),
    ),
  placeholderData: keepPreviousData,
  retry: 3,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- we don't know the actual type
export const useIncidentsWithQueryParams = (queryParams: any) =>
  useQuery(createIncidentsQueryWithQueryParams(queryParams));

export const useUnreadIncidentsCount = (isEnabled: boolean) =>
  useQuery({
    queryKey: [unreadIncidentsCountQueryKey],
    queryFn: () =>
      fetcher.get<{ unreadCount: number }>(`${INCIDENTS_BASE_PATH}/unread-count`),
    placeholderData: keepPreviousData,
    refetchInterval: 4000,
    enabled: isEnabled,
    retry: 3,
  });

type UpdateIncidentReadStatus = {
  incidentId: string;
  options: UpdateReadStatusIncidentBody;
};

export const useUpdateIncidentReadStatus = () => {
  const queryClient = useQueryClient();
  const updateIncidentReadStatus = ({ incidentId, options }: UpdateIncidentReadStatus) =>
    fetcher.patch<UpdateReadStatusIncidentBody, IncidentDTO>(
      `${INCIDENTS_BASE_PATH}/${incidentId}/actions/update-read-status`,
      options,
    );

  return useMutation<IncidentDTO, AxiosError, UpdateIncidentReadStatus>({
    mutationFn: updateIncidentReadStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [incidentsQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [unreadIncidentsCountQueryKey],
      });
    },
    retry: 3,
  });
};

export const createIncidentQuery = (incidentId: string) => ({
  queryKey: [incidentsQueryKey, incidentId],
  queryFn: () => fetcher.get<IncidentDTO>(`${INCIDENTS_BASE_PATH}/${incidentId}`),
  retry: 3,
});

export const useGetIncident = (incidentId: string) =>
  useQuery(createIncidentQuery(incidentId));
