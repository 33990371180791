import { Auth } from '@aws-amplify/auth';
import { Sidebar } from '@eppendorf/vnls-react-components';
import { User } from '@eppendorf/vnls-user-tenant-utils';
import { QueryClient } from '@tanstack/react-query';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigation } from 'react-router-dom';

import { AuthGuard } from '$shared/auth/auth-guard';
import { buildUserId } from '$shared/auth/build-user-id';
import { useAuthContext } from '$shared/auth/use-auth-context';
import { isFeatureEnabled } from '$shared/feature-toggle/is-feature-enabled';
import { queryClient } from '$shared/query/query-client';
import { checkPreferencesAndInitializePendo } from '$shared/tracking/pendo';
import { prepopulateCache } from '$shared/utils/react-query.utils';

import { Footer } from '$components/footer';
import { Header } from '$components/header/header';
import { SidebarBadge } from '$components/sidebar-badge/sidebar-badge';
import { SidecarRouter } from '$components/sidecar-router/sidecar-router';
import { useUnreadIncidentsCount } from '$features/incidents/incidents.api';
import { createGetUserQuery } from '$features/users/users.api';

import { Loader } from '../components/loader/loader';

import { createSidecarRoutes } from 'src/sidecar-routes';

export async function profileLoader(qc: QueryClient): Promise<null> {
  const currentSession: CognitoUserSession = await Auth.currentSession();
  const idToken = currentSession.getIdToken().payload;
  const currentUserId = await buildUserId(idToken.email, idToken.tenantId);
  return prepopulateCache<User>(qc, createGetUserQuery(currentUserId || ''));
}

export function RootLayout() {
  const navigation = useNavigation();
  const auth = useAuthContext();

  const { t } = useTranslation();

  const isIncidentsFeatureEnabled = isFeatureEnabled('showIncidentsTab');
  const unreadIncidentsCount = useUnreadIncidentsCount(isIncidentsFeatureEnabled);

  /**
   * Initialize pendo every time user is authenticated as we use tenantId for tracking
   */
  useEffect(() => {
    checkPreferencesAndInitializePendo();
  }, []);

  return (
    <>
      {navigation.state === 'loading' ? <Loader /> : <span />}

      <AuthGuard>
        <Sidebar>
          <Sidebar.Header />
          <Sidebar.Expander />
          <Sidebar.Navigation>
            <Sidebar.Group>
              <Sidebar.NavItem
                to="/devices"
                iconName="generic"
                label={t('sidebar.deviceManagement')}
              />
              <Sidebar.NavItem
                to="/monitoring"
                iconName="charts"
                label={t('sidebar.monitoring')}
              />
              <Sidebar.NavItem
                to="/notifications"
                iconName="notifications"
                label={t('sidebar.notifications')}
                badge={
                  <SidebarBadge>{unreadIncidentsCount.data?.unreadCount}</SidebarBadge>
                }
              />
              <Sidebar.NavItem
                to="/user-management"
                iconName="users"
                label={t('sidebar.userManagement')}
              />
            </Sidebar.Group>
          </Sidebar.Navigation>
        </Sidebar>

        <Header />
      </AuthGuard>

      <main>
        <Outlet />
        <SidecarRouter router={createSidecarRoutes(queryClient)} />
      </main>

      {!auth.isLoading && <Footer />}
    </>
  );
}
