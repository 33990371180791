import type { HasChildren } from '@eppendorf/vnls-react-components';
import {
  createContext,
  useContext,
  useImperativeHandle,
  type ForwardedRef,
  type RefObject,
} from 'react';

export interface ControlledSidecarRef {
  onSideCarClose: () => boolean;
}

const ControlledSidecarContext = createContext<RefObject<ControlledSidecarRef | null> | null>(
  null,
);

export const useControlledSidecarContext = () => {
  const context = useContext(ControlledSidecarContext);

  if (!context) {
    throw new Error(
      'useControlledSidecarContext must be used within a ControlledSidecarProvider',
    );
  }

  return context;
};

// children provide a callback to check if the sidecar can be closed
export const useControlledSidecar = (
  callback: () => boolean,
  ref: ForwardedRef<ControlledSidecarRef>,
) => useImperativeHandle(ref, () => ({ onSideCarClose: callback }));

export function ControlledSidecarProvider({
  children,
  onCloseRef,
}: HasChildren & { onCloseRef: RefObject<ControlledSidecarRef | null> }) {
  return <ControlledSidecarContext.Provider value={onCloseRef}>
    {children}
  </ControlledSidecarContext.Provider>
}
