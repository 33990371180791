import { QueryClient } from '@tanstack/react-query';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import { AuthGuard } from '$shared/auth/auth-guard';

import { appConfigLoader } from '$components/app-config/app-config-loader';
import { CookieList } from '$components/cookie-policy';
import { CookieElementWrapper } from '$components/cookie-policy/cookie-element-wrapper';
import { IncidentsList } from '$features/incidents-list/incidents-list';
import { NotificationRuleList } from '$features/notification-rule-list';
import { DevicesPage, devicesLoader } from '$pages/devices/devices-page';
import { ErrorPage } from '$pages/error-page';
import { SetupAccountPage } from '$pages/landing/setup-account/setup-account';
import { SigninAccountPage } from '$pages/landing/signin-account/signin-account';
import { monitoringLoader, MonitoringPage } from '$pages/monitoring/monitoring-page';
import { AsyncRedirect, AsyncRedirectLoader } from '$pages/notifications/AsyncRedirect';
import { Notifications, notificationsLoader } from '$pages/notifications/notifications';
import {
  librariesLoader,
  OpenSourceLibrariesPage,
} from '$pages/open-source-libraries/open-source-libraries-page';
import { profileLoader, RootLayout } from '$pages/root-layout';
import { SignoutPage } from '$pages/signout/signout-page';
import {
  UserManagementPage,
  usersLoader,
} from '$pages/user-management/user-management-page';

async function rootLoader(qc: QueryClient): Promise<null> {
  return Promise.all([profileLoader(qc), appConfigLoader(qc)])
    .then(() => null)
    .catch((error) => {
      if (error === 'No current user') {
        // In case user is not logged in yet, we dont want to see the errorElement of the root route.
        // Which means, we dont want to throw/reject here.
        return Promise.resolve(null);
      }

      throw new Error(error);
    });
}

export function createRouter(queryClient: QueryClient, isAuthenticated: boolean) {
  const navigateToDefaultPage = (path?: string) => {
    const navigateTo = path ? `/${path}` : '/devices';
    return <Navigate to={navigateTo} />;
  };

  return createBrowserRouter([
    {
      path: '/',
      loader: isAuthenticated ? () => rootLoader(queryClient) : undefined,
      element: (
        <AuthGuard fallback={<Navigate to="/signin" />}>
          <RootLayout />
        </AuthGuard>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          path: '',
          element: navigateToDefaultPage('devices'),
        },
        {
          path: 'devices',
          element: <DevicesPage />,
          errorElement: <DevicesPage />,
          loader: () => devicesLoader(queryClient),
        },
        {
          path: 'monitoring',
          element: <MonitoringPage />,
          errorElement: <MonitoringPage />,
          loader: () => monitoringLoader(queryClient),
        },
        {
          path: 'notifications',
          element: <Notifications />,
          errorElement: <Notifications />,
          loader: async () => notificationsLoader(queryClient),
          children: [
            {
              path: 'notifications-rules',
              element: <NotificationRuleList />,
              errorElement: <NotificationRuleList />,
            },
            {
              path: 'incidents',
              element: <IncidentsList />,
              errorElement: <IncidentsList />,
            },
            {
              path: '',
              element: <AsyncRedirect />,
              errorElement: <AsyncRedirect />,
              loader: async () => AsyncRedirectLoader(queryClient),
            },
          ],
        },
        {
          path: 'user-management',
          element: <UserManagementPage />,
          errorElement: <UserManagementPage />,
          loader: () => usersLoader(queryClient),
        },
        {
          path: 'cookie-list',
          element: (
            <CookieElementWrapper>
              <CookieList />
            </CookieElementWrapper>
          ),
        },
      ].map((route) => ({
        ...route,
        loader: isAuthenticated ? route.loader : undefined,
      })),
    },
    {
      path: 'signin',
      element: isAuthenticated ? navigateToDefaultPage() : <SigninAccountPage />,
    },
    {
      path: 'setup',
      element: isAuthenticated ? navigateToDefaultPage() : <SetupAccountPage />,
    },
    {
      path: 'oslibraries',
      element: <OpenSourceLibrariesPage />,
      loader: () => librariesLoader(),
    },
    {
      path: 'logout',
      element: <SignoutPage />,
    },
  ]);
}
